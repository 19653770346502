import { IGame } from "../../models/game";
import { IUser } from "../../models/user";
import { WindDirection } from "../../models/windEnum";
import { IRoundTile } from "../../models/tile";
import { createValidator } from "revalidate";
import { IGamePlayer } from "../../models/player";

export interface WindDirectionImage {
  imgPath: string;
  imgAlt: string;
}

export const botUserName = ["sayky", "nina", "fifi"];

export const isBotUser = (userName: string) : boolean => {
  return botUserName.includes(userName);
}

export const getWindOfEmptySeat = (gamePlayers: IGamePlayer[]): WindDirection => {

  const playerWithSeats = gamePlayers.filter(p => p.initialSeatWind !== undefined)

  let allWinds = [WindDirection.East, WindDirection.South, WindDirection.West, WindDirection.North]; 
  playerWithSeats.forEach((p) => {
    if(p.initialSeatWind === WindDirection.East){
      const indexOfEast = allWinds.indexOf(WindDirection.East);
      allWinds.splice(indexOfEast, 1);
    }else if(p.initialSeatWind === WindDirection.South){
      const indexOfSouth = allWinds.indexOf(WindDirection.South);
      allWinds.splice(indexOfSouth, 1);
    }else if(p.initialSeatWind === WindDirection.West){
      const indexOfWest = allWinds.indexOf(WindDirection.West);
      allWinds.splice(indexOfWest, 1);
    }else if(p.initialSeatWind === WindDirection.North){
      const indexOfNorth = allWinds.indexOf(WindDirection.North);
      allWinds.splice(indexOfNorth, 1);
    }
  })
  return allWinds[0];
}

export const getWindDirectionImg = (
  wind: WindDirection
): WindDirectionImage => {
  let ret: WindDirectionImage = { imgAlt: "", imgPath: "" };
  switch (wind) {
    case WindDirection.East:
      ret = {
        imgAlt: "prevailing-wind-east",
        imgPath: "/assets/seat_indicator/east_indicator.png",
      };
      break;
    case WindDirection.South:
      ret = {
        imgAlt: "prevailing-wind-south",
        imgPath: "/assets/seat_indicator/south_indicator.png",
      };
      break;
    case WindDirection.West:
      ret = {
        imgAlt: "prevailing-wind-west",
        imgPath: "/assets/seat_indicator/west_indicator.png",
      };
      break;
    case WindDirection.North:
      ret = {
        imgAlt: "prevailing-wind-north",
        imgPath: "/assets/seat_indicator/north_indicator.png",
      };
      break;
  }
  return ret;
};

export const GetOtherUserWindPosition = (
  currentUserWind: WindDirection,
  direction: string
) => {
  switch (direction) {
    case "left":
      switch (currentUserWind) {
        case WindDirection.East:
          return WindDirection.North;
        case WindDirection.South:
          return WindDirection.East;
        case WindDirection.West:
          return WindDirection.South;
        case WindDirection.North:
          return WindDirection.West;
      }
      break;
    case "right":
      switch (currentUserWind) {
        case WindDirection.East:
          return WindDirection.South;
        case WindDirection.South:
          return WindDirection.West;
        case WindDirection.West:
          return WindDirection.North;
        case WindDirection.North:
          return WindDirection.East;
      }
      break;
    case "top":
      switch (currentUserWind) {
        case WindDirection.East:
          return WindDirection.West;
        case WindDirection.South:
          return WindDirection.North;
        case WindDirection.West:
          return WindDirection.East;
        case WindDirection.North:
          return WindDirection.South;
      }
      break;
  }
};

export const sortByActiveCounter = (a: IRoundTile, b: IRoundTile) => {
  if (a.activeTileCounter > b.activeTileCounter) return 1;
  if (a.activeTileCounter < b.activeTileCounter) return -1;
  return 0;
};

export const sortTiles = (a: IRoundTile, b: IRoundTile) => {
  if (a.status > b.status) return -1;
  if (a.status < b.status) return 1;
  if (a.tileSetGroupIndex < b.tileSetGroupIndex) return -1;
  if (a.tileSetGroupIndex > b.tileSetGroupIndex) return 1;
  if (a.tile.tileType > b.tile.tileType) return -1;
  if (a.tile.tileType < b.tile.tileType) return 1;
  if (a.tile.tileValue > b.tile.tileValue) return 1;
  if (a.tile.tileValue < b.tile.tileValue) return -1;
  return 0;
};

export const isLessThan = (n: number) =>
  createValidator(
    (message) => (value) => {
      if (value && Number(value) >= n) {
        return message;
      }
    },
    (field) => `${field} must be less than ${n}`
  );

export const isGreaterThan = (n: number) =>
  createValidator(
    (message) => (value) => {
      if (value && Number(value) <= n) {
        return message;
      }
    },
    (field) => `${field} must be greater than ${n}`
  );

export const combineDateAndTime = (date: Date, time: Date) => {
  const dateString = date.toISOString().split("T")[0];
  const timeString = time.toISOString().split("T")[1];

  return new Date(dateString + " " + timeString);
};

export const setGameProps = (game: IGame, user: IUser) => {
  let currentPlayer = game.gamePlayers.find(
    (p) => p.userName === user.userName
  );
  game.date = new Date(game.date);

  game.isCurrentPlayerConnected = game.gamePlayers.some(
    (p) => p.userName === user.userName
  );

  game.isHost = game.hostUserName === user.userName;

  if (currentPlayer?.initialSeatWind !== undefined) {
    game.initialSeatWind = game.gamePlayers.find(
      (p) => p.userName === user.userName
    )!.initialSeatWind;
  } else {
    game.initialSeatWind = WindDirection.Unassigned;
  }
  return game;
};
