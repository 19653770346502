import {
  observable,
  action,
  runInAction,
} from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { IBeatTheBotLadder } from "../models/beatTheBotLadder";
import { history } from "../..";

export default class BeaTheBotStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @observable activeTab: number = 0;
  @observable loadingLadderInitial = false;
  @observable beatTheBotLadders: IBeatTheBotLadder[] | null = null;

  @action setActiveTab = (activeIndex: number) => {
      this.activeTab = activeIndex;
  } 

  @action submitBeatTheBotEntry = async (gameCode:string) => {
    const result = await agent.BeatTheBots.create(gameCode);
    if(result === 'success'){
      history.push(`/beatthebots/BeatTheBotSubmissionSuccess?gameCode=${gameCode}`);
    }
  }

  @action loadLadders = async () => {
    this.loadingLadderInitial = true;
    try {
      const laddersEnv = await agent.BeatTheBots.list();
      runInAction("loading ladder", () => {
        this.beatTheBotLadders = laddersEnv.ladders;
        this.loadingLadderInitial = false;
      });
    } catch (error) {
      runInAction("load ladder error", () => {
        this.loadingLadderInitial = false;
      });
      console.log(error);
    }
  };
}