import React, { Fragment, useContext } from "react";
import {
  Segment,
  Image,
  Grid,
  Header,
  List,
  Item,
  Label,
  Button,
  Icon,
} from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { WindDirection } from "../../../app/models/windEnum";
import GameLobbySeat from "./GameLobbySeat";
import { Link } from "react-router-dom";
import { IGamePlayer } from "../../../app/models/player";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { botUserName, isBotUser } from "../../../app/common/util/util";
import { toJS } from "mobx";
import { GameStatus } from "../../../app/models/gameStatusEnum";

interface IProps {
  players: IGamePlayer[];
}

const GameLobbySidebar: React.FC<IProps> = ({ players }) => {
  const rootStore = useContext(RootStoreContext);
  const { game, currentBots } = rootStore.gameStore;
  const {
    hubLoading,
    addBot,
    botSit,
    botLeave,
    botStandUp,
    randomizeWind,
  } = rootStore.hubStore;

  const fillBots = () => {
    let needBotCount = 4 - players.length;
    let cb = toJS(currentBots);
    var availableBots = botUserName.filter((e) => !cb.includes(e));
    for (let i = 0; i < needBotCount; i++) {
      addBot(availableBots[i]);
    }
  };

  return (
    <Fragment>
      <Segment
        attached="top"
        inverted
        color="teal"
        style={{ border: "none", minHeight: "55px" }}
      >
        {game &&
          game.status === GameStatus.Created &&
          game.isHost &&
          game.gamePlayers.length === 4 && (
            <Button
              size="mini"
              floated="right"
              color="orange"
              loading={hubLoading}
              onClick={randomizeWind}
            >
              Shuffle Seat
            </Button>
          )}

        {players.length !== 4 && game?.isHost && (
          <Button
            loading={hubLoading}
            color="olive"
            size="mini"
            floated="right"
            icon
            labelPosition="right"
            onClick={fillBots}
          >
            ADD BOTS <Icon name="add circle" />
          </Button>
        )}
        <Header floated="left">Players {`${players.length}/4`}</Header>
      </Segment>
      <Segment attached>
        <List horizontal relaxed size="mini">
          {players.map((player) => (
            <Item key={player.userName} style={{ position: "relative" }}>
              <Image
                circular
                size="mini"
                src={player.image || "/assets/user.png"}
              />
              <Item.Content verticalAlign="middle">
                <Item.Header as="h3">
                  <Link to={`/profile/${player.userName}`}>
                    {player.displayName}
                  </Link>
                </Item.Header>
                {player.isHost && (
                  <Label color="orange" size="mini">
                    Host
                  </Label>
                )}
                {isBotUser(player.userName) && game?.isHost && game.status === GameStatus.Created && (
                  <Item.Extra>
                    {players.length === 4 && (
                      <Fragment>
                        {player.initialSeatWind === null ||
                        player.initialSeatWind === undefined ? (
                          <Button
                            color="teal"
                            onClick={() => botSit(player.userName)}
                            size="mini"
                          >
                            Sit
                          </Button>
                        ) : (
                          <Button
                            onClick={() => botStandUp(player.userName)}
                            size="mini"
                          >
                            Stand-Up
                          </Button>
                        )}
                      </Fragment>
                    )}
                    <Button
                      onClick={() => botLeave(player.userName)}
                      size="mini"
                    >
                      Kick
                    </Button>
                  </Item.Extra>
                )}
              </Item.Content>
            </Item>
          ))}
        </List>
      </Segment>
      {players.length === 4 && (
        <Fragment>
          <Segment
            attached="top"
            inverted
            color="teal"
            style={{ border: "none" }}
          >
            <Header textAlign="center">Table</Header>
          </Segment>
          <Segment attached>
            <Grid verticalAlign="middle" centered>
              <Grid.Row>
                <Grid.Column width={4}></Grid.Column>
                <Grid.Column textAlign="center" width={8}>
                  <GameLobbySeat wind={WindDirection.South} />
                </Grid.Column>
                <Grid.Column width={4}></Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4} textAlign="center">
                  <GameLobbySeat wind={WindDirection.West} />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Image size="medium" src={"/assets/seat_indicator.png"} />
                </Grid.Column>
                <Grid.Column width={4} textAlign="center">
                  <GameLobbySeat wind={WindDirection.East} />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={4}></Grid.Column>
                <Grid.Column textAlign="center" width={8}>
                  <GameLobbySeat wind={WindDirection.North} />
                </Grid.Column>
                <Grid.Column width={4}></Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Fragment>
      )}
    </Fragment>
  );
};

export default observer(GameLobbySidebar);
