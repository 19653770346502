import React from "react";
import {
  Container,
  Divider,
  Header,
  Icon,
  List,
  Popup,
  Segment,
} from "semantic-ui-react";

const ChangeLog = () => {
  return (
    <Container>
      <Segment>
        <Header textAlign="center" size="medium">
          Change Log
        </Header>
        <Divider></Divider>
        <List divided>
        <List.Item>
            <p>
              <strong>5/11/2021 update</strong>
            </p>
            <p>
              - Added Bot feature.
              <ul>
                <li>Only game host can add/remove bot to the game.</li>
                <li>Bot can be added independently - whether you need 1/2/3 bots to get your game running.</li>
                <li>Bot will use different strategy depending on the minimum point of the game.</li>
              </ul>
            </p>
            <p>- Improvement on connecting with multiple devices.</p>
            <ul>
              <li>When you are playing with different devices, all devices now will get the changes.</li>
              <li>This is a setup for next update where I will make the game to be mobile compatible. Play everywhere!</li>
            </ul>
            <p>- Optimization on the tile images. This will fix issue when user sometimes see tile without image and had to refresh.</p>
            <p>- Removed delay when throwing the tile. The game should feel slightly faster with this change.</p>
            <p>- Migrated MahjongBuddy to private hosting. Thank you all for the supports to make this happens.</p>
            <p>- Moved Ko-fi button to navbar and added email to footer area.
              <ul>
                <li>I want to make the donation button to be more visible yet not too distracting (Thank you Jennifer for the suggestion).</li>
              </ul>
            </p>
            <p>- Bug fixes ~ we now have a channel in discord for bug report/suggestion.
              <ul>
                <li>Added error checking when user throw a tile to prevent extra/missing tile.</li>
                <li>Fixed bug where user unable to update their display name.</li>
                <li>Remove kong option when there is no more tile left.</li>
                <li>Fixed wrong point calculation for Mixed Terminal hand (Thanks for the report Cindy).</li>
                <li>Fixed issue when reordering tile(Thanks for the report SakuraRin).</li>
              </ul>
              </p>         
          </List.Item>
          <List.Item>
            <p>
              <strong>3/10/2021 update</strong>
            </p>
            <p>
              - Added optional jackpot feature when creating a new game<img
                width="30"
                src="/assets/jackpot.png"
                alt="jackpot"
              />.
              <p>{" "}What is jackpot?</p>
              <ul>
                <li>Whenever a tie occurs/nobody win in a round, each player take off 1pt and put in 1 pt to the jackpot.</li>
                <li>To win the jackpot, you need to get min of 10 pts in one hand.</li>
                <li>If jackpot is unclaimed when game ended, it will be distributed back to the players</li>
              </ul>
            </p>
            <p>
              - Bug fixes ~ If you guys found a bug, please take extra effort to report it to me, it will only make the game better.
              <ul>
                <li>Fix bug where user marked as lost with penalty/bao even though the user didn't</li>
                <li>Fix bug on thirteen orphan recognized even with wrong eyes(Thank you for the report Amy)</li>
              </ul>
            </p>            
          </List.Item>
          <List.Item>
            <p>
              <strong>1/20/2021 update</strong>
            </p>
            <p>
              - Changed prevailing game indicator image{" "}
              <img
                width="30"
                src="/assets/seat_indicator/east_indicator.png"
                alt="prevailing-wind-east"
              />{" "}
              and it's location to be next to the player.
            </p>
            <p>- Updated board's background color to green color.</p>
            <p>
              - Moved around several items:
              <ul>
                <li>Player's action button now located at the center.</li>
                <li>Remaining tiles indicator now located at bottom right.</li>
              </ul>
            </p>
            <p>
              - Added more sound effects to below situation (shout-out to <strong>Jeffery</strong>, this is for you):
              <ul>
                <li>Player's turn</li>
                <li>Player has action</li>
                <li>Action button clicked</li>
                <li>Voice over for player's action</li>
              </ul>
            </p>
            <p>- Added animation when user activated an action.</p>
            <p>
              - Self picked now has "SELFPICKED" button and{" "}
              <strong>awesome sound effect</strong>. you have to get self picked
              to find that out lol.
            </p>
            <p>
              - Created a Discord server community{" "}
              <strong>
                <a href="https://discord.gg/XjHTU3Yrzg">
                  <Icon name="discord" size="big" />
                </a>
              </strong>
              for below purposes -{" "}
              <strong>
                <a href="https://discord.gg/XjHTU3Yrzg">
                  https://discord.gg/XjHTU3Yrzg
                </a>
              </strong>
              <ul>
                <li>
                  Get enough people to get the game running. There is a
                  #recruit-legs channel in discord where you can recruit people
                  for your game.
                </li>
                <li>
                  Place where people can talk with each others. I have created
                  multiple chat rooms so that people can do voice chat while
                  playing.
                </li>
                <li>Say Hi! to me :) my discord handler is greenteatv.</li>
              </ul>
            </p>
            <p>
              - Bug fixes. Thank you for the bug report! the game will get better for every report. email bugs/feedback to me at info@mahjongbuddy.com or message me directly through discord channel:
              <ul>
                <li>
                  win button won't show up even when meeting minimum point.
                </li>
                <li>
                  user lost with penalty even though the player did not bao.
                </li>
              </ul>
            </p>
            <p></p>
          </List.Item>

          <List.Item>
            <p></p>
            <p>
              <strong>1/4/2021 update</strong>
            </p>
            <p>
              - Joining game now use 5 letters code instead of number eg. RAWRR.
            </p>
            <p>
              - Added player's picture in the game. Go to "My Profile" (top
              right navigation bar) to add/change your photo and set it as main
              picture.
            </p>
            <p>
              - Added different color highlight in player's turn. Green when
              it's player's turn but have not thrown a tile, and light green
              when player has thrown a tile.
            </p>
            <p>- Reduce delay after throwing tile to 1.5 seconds.</p>
            <p>
              - Allow more than one winners. Player can now declare win after
              another player declared win.
            </p>
            <p>
              - Added <Icon name="book" /> in the game (located in the top right
              when playing) so that player can easily access list of supported
              hand types and supported extra points.
            </p>
            <p>
              - When a round is finished, game host now has an option to either
              end the game or continue to the next round. If game host decided
              to end the game, there will be a points calculation.
            </p>
            <p>
              - Added pop over(hover your mouse on the icon) to explain{" "}
              <Popup
                content="I threw the dice this round"
                trigger={<Icon name="cube" />}
              />{" "}
              and{" "}
              <Popup
                content="I'm the very first dealer of the game"
                trigger={<Icon name="star" />}
              />{" "}
              icon (I promise I will use better image when I start styling the
              game).
            </p>
            <p>
              - Added min and max point information in the game. Limited the max
              point value to be 0-100 when creating a game.
            </p>
            <p>
              - Added sound effect when hovering on tile and when there's a new
              tile thrown to board (more sound effects are coming in the next
              update). Also added <Icon name="volume up" /> icon in the game to
              turn on/off the sound.
            </p>
            <p>
              - I have created Facebook and twitter page. Share your experience
              with me{" "}
              <List horizontal>
                <List.Item
                  as="a"
                  target="_blank"
                  href="https://www.facebook.com/MahjongBuddyFB"
                >
                  <List.Content verticalAlign="top">
                    <Icon name="facebook" size="big" color="blue" />
                  </List.Content>
                </List.Item>
                <List.Item
                  as="a"
                  target="_blank"
                  href="https://www.twitter.com/MahjongBuddy"
                >
                  <List.Content verticalAlign="top">
                    <Icon name="twitter square" size="big" color="blue" />
                  </List.Content>
                </List.Item>
              </List>
              or email me directly at{" "}
              <a href="mailto:info@mahjongbuddy.com">info@mahjongbuddy.com</a>{" "}
              for your feedback.
            </p>
          </List.Item>
        </List>
      </Segment>
    </Container>
  );
};

export default ChangeLog;
