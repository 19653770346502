import React, { Fragment, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { IRoundTile, TileType, TileValue } from "../../../app/models/tile";
import { Droppable } from "react-beautiful-dnd";
import {
  Grid,
  Button,
  Transition,
  Image,
  Popup,
  Item,
  Segment,
  Label,
} from "semantic-ui-react";
import PlayerStatus from "./PlayerStatus";
import DraggableTile from "./DraggableTile";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { runInAction, toJS } from "mobx";
import { sortTiles } from "../../../app/common/util/util";
import { IRoundPlayer } from "../../../app/models/player";
import useSound from "use-sound";
import playerTurnSfx from "../../../app/common/sounds/playerTurn.mp3";
import PrevailingWindIndicator from "./PrevailingWindIndicator";

interface IProps {
  mainPlayer: IRoundPlayer | undefined;
  containerStyleName: string;
  tileStyleName: string;
  mainPlayerAliveTiles: IRoundTile[] | null;
  mainPlayerGraveYardTiles: IRoundTile[] | null;
  doThrowTile: (tileId: string) => void;
}
const square = { padding: "0.5em" };

const isPlayerFlower = (rt: IRoundTile, player: IRoundPlayer): boolean => {
  if (rt.tile.tileType !== TileType.Flower) return false;
  const userFlowerNum = player.wind + 1;
  const tv = rt.tile.tileValue;
  let isUserFlower = false;
  switch (userFlowerNum) {
    case 1: {
      if (tv === TileValue.FlowerNumericOne || tv === TileValue.FlowerRomanOne)
        isUserFlower = true;
      break;
    }
    case 2: {
      if (tv === TileValue.FlowerNumericTwo || tv === TileValue.FlowerRomanTwo)
        isUserFlower = true;
      break;
    }
    case 3: {
      if (
        tv === TileValue.FlowerNumericThree ||
        tv === TileValue.FlowerRomanThree
      )
        isUserFlower = true;
      break;
    }
    case 4: {
      if (
        tv === TileValue.FlowerNumericFour ||
        tv === TileValue.FlowerRomanFour
      )
        isUserFlower = true;
      break;
    }
  }
  return isUserFlower;
};

const MainPlayerSection: React.FC<IProps> = ({
  mainPlayer,
  containerStyleName,
  mainPlayerAliveTiles,
  mainPlayerGraveYardTiles,
  doThrowTile,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.userStore;
  const { game, gameSound } = rootStore.gameStore;
  const {
    isManualSort,
    hasNonSelfPickAction,
    remainingTiles,
    jackpotCounter,
  } = rootStore.roundStore;
  const { orderTiles } = rootStore.hubStore;
  const [playplayerTurnSfx] = useSound(playerTurnSfx);

  useEffect(() => {
    if (gameSound) {
      if (
        mainPlayer?.isMyTurn &&
        mainPlayer.mustThrow &&
        !hasNonSelfPickAction
      ) {
        playplayerTurnSfx();
      }
    }
  }, [gameSound, playplayerTurnSfx, mainPlayer, hasNonSelfPickAction]);

  const autoSort = async () => {
    const beforeOrderingTiles = toJS(mainPlayerAliveTiles!, {
      recurseEverything: true,
    });
    const beforeOrderingManualSortValue = toJS(
      rootStore.roundStore.isManualSort
    );

    const sortedTile = mainPlayerAliveTiles!;
    sortedTile.sort(sortTiles);
    for (let i = 0; i < sortedTile.length; i++) {
      let objIndex = rootStore.roundStore.mainPlayer!.playerTiles!.findIndex(
        (obj) => obj.id === sortedTile[i].id
      );

      runInAction("updating reordered tile", () => {
        rootStore.roundStore.mainPlayer!.playerTiles![
          objIndex
        ].activeTileCounter = i;
      });
    }
    runInAction("autosort", () => {
      rootStore.roundStore.isManualSort = false;
    });

    await orderTiles(
      sortedTile,
      beforeOrderingTiles,
      beforeOrderingManualSortValue
    );
  };

  return (
    <Grid id="mainPlayerTiles">
      <Grid.Column width={3}>
        <Grid.Row centered style={{ padding: "1px" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start",
              alignContent: "flex-start",
            }}
          >
            {mainPlayerGraveYardTiles &&
              mainPlayerGraveYardTiles.map((rt) => (
                <Fragment key={rt.id}>
                  {rt.tile.tileType === TileType.Flower ? (
                    <Transition
                      transitionOnMount={true}
                      animation="shake"
                      duration={1000}
                    >
                      <div
                        className={`${
                          isPlayerFlower(rt, mainPlayer!)
                            ? "goodFlowerTile"
                            : ""
                        } tile50px tile50px-${rt.tile.title}`}
                      />
                    </Transition>
                  ) : (
                    <div className={`tile50px tile50px-${rt.tile.title}`} />
                  )}
                </Fragment>
              ))}
          </div>
        </Grid.Row>
      </Grid.Column>
      <Grid.Column width={10}>
        <Grid.Row>
          <Droppable droppableId="tile" direction="horizontal">
            {(provided, snapshot) => (
              <Fragment>
                <DraggableTile
                  doThrowTile={doThrowTile}
                  containerStyleName={containerStyleName}
                  tiles={mainPlayerAliveTiles!}
                  droppableSnapshot={snapshot}
                  droppableProvided={provided}
                />
              </Fragment>
            )}
          </Droppable>
        </Grid.Row>
        <Grid.Row>
          <Grid>
            <Grid.Column width={3}>
              <Image
                floated="right"
                style={{ width: "50%" }}
                circular
                src={user!.image || "/assets/user.png"}
              />
            </Grid.Column>
            <Grid.Column width={10}>
              <div
                style={{ borderRadius: "25px" }}
                className="playerStatusContainer"
                {...(mainPlayer!.isMyTurn &&
                  mainPlayer!.mustThrow && {
                    className: "mustThrow playerStatusContainer",
                  })}
                {...(mainPlayer!.isMyTurn &&
                  !mainPlayer!.mustThrow && {
                    className: "playerTurn playerStatusContainer",
                  })}
              >
                {mainPlayer && (
                  <Fragment>
                    <span
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {isManualSort && (
                        <Button
                          className="actionButton"
                          circular
                          size="tiny"
                          color="green"
                          onClick={autoSort}
                          content="SORT"
                        />
                      )}

                      <PlayerStatus player={mainPlayer} />
                    </span>
                  </Fragment>
                )}
              </div>
            </Grid.Column>
            <Grid.Column width={3}>
              {mainPlayer?.isInitialDealer && <PrevailingWindIndicator />}
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Grid.Column>
      <Grid.Column width={3} verticalAlign="bottom">
        <Segment.Group horizontal style={{ backgroundColor: "#2f7b42" }}>
          <Popup
            content="Remaining unopen tiles"
            trigger={
              <Segment circular textAlign="center" style={square}>
                <Item.Group>
                  <Item>
                    <Item.Image
                      style={{ width: "20px" }}
                      size="mini"
                      src="/assets/tiles/50px/face-down.png"
                    />
                    <Item.Content
                      verticalAlign="middle"
                      className="remainingTileHeader"
                    >
                      <Item.Header>{`${remainingTiles}`}</Item.Header>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Segment>
            }
          />
          <Popup
            content={`Minimum of ${game?.minPoint} pts for the win button to appear. Maximum of ${game?.maxPoint} pts per round.`}
            trigger={
              <Segment textAlign="center" style={square}>
                <Item.Group>
                  <Item>
                    <Item.Content
                      verticalAlign="middle"
                      className="remainingTileHeader"
                    >
                      {`Min:${game?.minPoint}`}
                      <br />
                      {`Max:${game?.maxPoint}`}
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Segment>
            }
          />
          {game?.useJackpot && (
            <Popup
              content={`Jackpot - For every tied round, each player put in 1 pt to the jackpot. To win the jackpot, you need to get min of 10 pts in one hand. If jackpot is unclaimed when game ended, it will be distributed back to the players.`}
              trigger={
                <Segment textAlign="center" style={{ paddingRight: "1px" }}>
                  <Image src="/assets/jackpot.png" />
                  <Label circular color="green" floating>
                    {jackpotCounter}
                  </Label>
                </Segment>
              }
            />
          )}
        </Segment.Group>
      </Grid.Column>
    </Grid>
  );
};
export default observer(MainPlayerSection);
