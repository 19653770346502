import React, { useState, useContext, useEffect, Fragment } from "react";
import { Segment, Form, Button, Grid } from "semantic-ui-react";
import { GameFormValues } from "../../../app/models/game";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";
import { RootStoreContext } from "../../../app/stores/rootStore";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { Formik } from "formik";
import * as Yup from "yup";
import MyCheckbox from "../../../app/common/form/MyCheckbox";

const validationSchema = Yup.object({
  title: Yup.string().required("game title is required"),
  minPoint: Yup.number().typeError("must be an number").min(0).max(15).required().integer(),
  maxPoint: Yup.number().typeError("must be an number").min(1).max(100).required().positive().integer()
});


interface DetailParams {
  id: string;
}
const GameForm: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
  history,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { createGame, editGame, loadGame } = rootStore.gameStore;

  const [game, setGame] = useState(new GameFormValues());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (match.params.id) {
      setLoading(true);
      loadGame(match.params.id)
        .then((game) => {
          setGame(new GameFormValues(game));
        })
        .finally(() => setLoading(false));
    }
  }, [loadGame, match.params.id]);

  const handleFormSubmit = (values: any) => {
    const dateAndTime = new Date();
    const { date, ...game } = values;
    game.date = dateAndTime;
    if (!game.id) {
      let newGame = {
        ...game,
      };
      createGame(newGame);
    } else {
      editGame(game);
    }
  };

  return (
    <Fragment>
      <Grid>
        <Grid.Column width={10}>
          <Segment clearing>
            <Formik
              validationSchema={validationSchema}
              enableReinitialize
              initialValues={game}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, isValid, isSubmitting, dirty }) => (
                <Form
                  className="ui form"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <MyTextInput name="title" placeholder="Game Title" />
                  <MyTextInput name="minPoint" placeholder="Minimum points to win: eg. 0 for beginner, 3 for advanced" />
                  <MyTextInput name="maxPoint" placeholder="Maximum points player can win from other player: eg. 10" />
                  <MyCheckbox showPopup={true} 
                  popupContent="Jackpot - For every tied round, each player put in 1 pt to the jackpot. To win the jackpot, you need to get min of 10 pts in one hand. If jackpot is unclaimed when game ended, it will be distributed back to the players." 
                  name="useJackpot" label='Jackpot' value="useJackpot" />

                  <Button
                    disabled={loading || isSubmitting || !dirty || !isValid}
                    loading={isSubmitting}
                    floated="right"
                    positive
                    type="submit"
                    content="Submit"
                  />
                  <Button
                    onClick={
                      game.id
                        ? () => history.push(`/games/${game.gameCode}`)
                        : () => history.push("/")
                    }
                    disabled={loading}
                    floated="right"
                    type="button"
                    content="Cancel"
                  />
                </Form>
              )}
            </Formik>
          </Segment>
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default observer(GameForm);
