import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Image, Popup } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { getWindDirectionImg } from "../../../app/common/util/util";

const PrevailingWindIndicator: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { round } = rootStore.roundStore;
  return (
    <Popup
      content="Current prevailing wind and this player is the very first dealer of the game"
      trigger={
        <Image
          style={{ height: "50px" }}
          circular
          src={getWindDirectionImg(round!.wind).imgPath}
        />
      }
    />
  );
};

export default observer(PrevailingWindIndicator);
