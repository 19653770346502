import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { IRoundTile } from "../../../app/models/tile";
import {
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { TileStatus } from "../../../app/models/tileStatus";
import useSound from 'use-sound';
import tileSelectSfx from '../../../app/common/sounds/tileSelect.mp3';
import { RootStoreContext } from "../../../app/stores/rootStore";

//import pin1 from './assets/tiles/64px/pin/pin1.png';
//import pin2 from './assets/tiles/64px/pin/pin2.png';
//import pin3 from './assets/tiles/64px/pin/pin3.png';
//import pin4 from './assets/tiles/64px/pin/pin4.png';
//import pin5 from './assets/tiles/64px/pin/pin5.png';
//import pin6 from './assets/tiles/64px/pin/pin6.png';
//import pin7 from './assets/tiles/64px/pin/pin7.png';
//import pin8 from './assets/tiles/64px/pin/pin8.png';
//import pin9 from './assets/tiles/64px/pin/pin9.png';
//import dragonChun from './assets/tiles/64px/dragon/dragon-chun.png';
//import dragonGreen from './assets/tiles/64px/dragon/dragon-green.png';
//import dragonWhite from './assets/tiles/64px/dragon/dragon-white.png';
//import flower1 from './assets/tiles/64px/flower/flower1.png';
//import flower1a from './assets/tiles/64px/flower/flower1a.png';
//import flower2 from './assets/tiles/64px/flower/flower2.png';
//import flower2b from './assets/tiles/64px/flower/flower2b.png';
//import flower3 from './assets/tiles/64px/flower/flower3.png';
//import flower3c from './assets/tiles/64px/flower/flower3c.png';
//import flower4 from './assets/tiles/64px/flower/flower4.png';
//import flower4d from './assets/tiles/64px/flower/flower4d.png';
//import man1 from './assets/tiles/64px/man/man1.png';
//import man2 from './assets/tiles/64px/man/man2.png';
//import man3 from './assets/tiles/64px/man/man3.png';
//import man4 from './assets/tiles/64px/man/man4.png';
//import man5 from './assets/tiles/64px/man/man5.png';
//import man6 from './assets/tiles/64px/man/man6.png';
//import man7 from './assets/tiles/64px/man/man7.png';
//import man8 from './assets/tiles/64px/man/man8.png';
//import man9 from './assets/tiles/64px/man/man9.png';
//import bamboo1 from './assets/tiles/64px/bamboo/bamboo1.png';
//import bamboo2 from './assets/tiles/64px/bamboo/bamboo2.png';
//import bamboo3 from './assets/tiles/64px/bamboo/bamboo3.png';
//import bamboo4 from './assets/tiles/64px/bamboo/bamboo4.png';
//import bamboo5 from './assets/tiles/64px/bamboo/bamboo5.png';
//import bamboo6 from './assets/tiles/64px/bamboo/bamboo6.png';
//import bamboo7 from './assets/tiles/64px/bamboo/bamboo7.png';
//import bamboo8 from './assets/tiles/64px/bamboo/bamboo8.png';
//import bamboo9 from './assets/tiles/64px/bamboo/bamboo9.png';
//import windEast from './assets/tiles/64px/wind/wind-east.png';
//import windNorth from './assets/tiles/64px/wind/wind-north.png';
//import windSouth from './assets/tiles/64px/wind/wind-south.png';
//import windWest from './assets/tiles/64px/wind/wind-west.png';




interface IProps {
  containerStyleName: string;
  tiles: IRoundTile[];
  droppableProvided: DroppableProvided;
  droppableSnapshot: DroppableStateSnapshot;
  doThrowTile: (tileId: string) => void;
}

const DraggableTile: React.FC<IProps> = ({
  tiles,
  droppableProvided,
  droppableSnapshot,
  containerStyleName,
  doThrowTile
}) => {
  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "lightblue" : "",
  });
  const rootStore = useContext(RootStoreContext);
  const {
    gameSound,
  } = rootStore.gameStore;
  const [play, {stop}] = useSound(tileSelectSfx, { volume: 0.25 });

  return (
    <span
      {...droppableProvided.droppableProps}
      ref={droppableProvided.innerRef}
      style={getListStyle(droppableSnapshot.isDraggingOver)}
    >
      {tiles.map((rt: IRoundTile, index) => (
        <Draggable key={rt.id} draggableId={rt.id} index={index}>
          {(provided, snapshot) => (
            <span
              onDoubleClick={() => doThrowTile(rt.id)}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={containerStyleName}
            >
              <div 
                {...(gameSound && {onMouseEnter:() => play(), onMouseLeave: () => stop()})}
                className={`flexTiles activeTiles tile64px tile64px-${rt.tile.title}`}
                {...(rt.status === TileStatus.UserJustPicked && {
                  className: `flexTiles justPickedTile tile64px tile64px-${rt.tile.title}`,
                })}
              />
            </span>
          )}
        </Draggable>
      ))}
      {droppableProvided.placeholder}
    </span>
  );
};
export default observer(DraggableTile);
