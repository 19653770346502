import React, { Fragment, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { IRoundPlayerAction } from "../../../app/models/player";
import { ActionType } from "../../../app/models/actionTypeEnum";
import { Transition } from "semantic-ui-react";
import useSound from "use-sound";
import tonnyVoiceOverSfx from "../../../app/common/sounds/voice-over_tonny.mp3";
import { RootStoreContext } from "../../../app/stores/rootStore";

interface IProps {
  activatedAction: IRoundPlayerAction;
}
const ActionActivated: React.FC<IProps> = ({ activatedAction }) => {
  const rootStore = useContext(RootStoreContext);
  const { gameSound, gameIsOver } = rootStore.gameStore;
  const [playTonnyVoiceOver] = useSound(tonnyVoiceOverSfx, {
    sprite: {
      chow: [0, 450],
      pong: [500, 450],
      kong: [1000, 450],
      sek: [1500, 450],
      zimo: [2000, 450]
    }
  });
  const animationStyle = "zoom";
  const animationDuration = 500;
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (gameSound && !gameIsOver) {
      if (activatedAction.actionType === ActionType.Chow) {
        playTonnyVoiceOver({id: 'chow'});
      }
      else if (activatedAction.actionType === ActionType.Pong) {
        playTonnyVoiceOver({id: 'pong'});
      }      
      else if (activatedAction.actionType === ActionType.Kong || activatedAction.actionType === ActionType.SelfKong) {
        playTonnyVoiceOver({id: 'kong'});        
      }      
      else if (activatedAction.actionType === ActionType.Win) {
        playTonnyVoiceOver({id: 'sek'});        
      }
      else if (activatedAction.actionType === ActionType.SelfWin) {
        playTonnyVoiceOver({id: 'zimo'});        
      }
    }
  }, [playTonnyVoiceOver, gameSound, activatedAction, gameIsOver]);

  useEffect(() => {
    setIsVisible(true);
    let visibleTimer =  setTimeout(() => setIsVisible(false), 3000);    
    return clearTimeout(visibleTimer);
  }, [activatedAction, isVisible])
  return (
    <Fragment>
      {activatedAction.actionType === ActionType.Chow && (
        <Transition
          visible={isVisible}
          transitionOnMount={true}
          animation={animationStyle}
          duration={animationDuration}
        >
          <div className='pactions pactions-chow' />
          {/* <Image src="/assets/actions/chow.png" /> */}
        </Transition>
      )}
      {activatedAction.actionType === ActionType.Pong && (
        <Transition
          visible={isVisible}
          transitionOnMount={true}
          animation={animationStyle}
          duration={animationDuration}
        >
          <div className='pactions pactions-pong' />
          {/* <Image src="/assets/actions/pong.png" /> */}
        </Transition>
      )}

      {(activatedAction.actionType === ActionType.Kong ||
        activatedAction.actionType === ActionType.SelfKong) && (
        <Transition
          visible={isVisible}
          transitionOnMount={true}
          animation={animationStyle}
          duration={animationDuration}
        >
          <div className='pactions pactions-kong' />
          {/* <Image src="/assets/actions/kong.png" /> */}
        </Transition>
      )}

      {activatedAction.actionType === ActionType.Win && (
        <Transition
          visible={isVisible}
          transitionOnMount={true}
          animation={animationStyle}
          duration={animationDuration}
        >
          <div className='pactions pactions-win' />
          {/* <Image src="/assets/actions/win.png" /> */}
        </Transition>
      )}

      {activatedAction.actionType === ActionType.SelfWin && (
        <Transition
          visible={isVisible}
          transitionOnMount={true}
          animation={animationStyle}
          duration={animationDuration}
        >
          <div className='pactions pactions-win' />
          {/* <Image src="/assets/actions/win.png" /> */}
        </Transition>
      )}
    </Fragment>
  );
};

export default observer(ActionActivated);
