import React, { useContext, Fragment, useState } from "react";
import { observer } from "mobx-react-lite";
import { IRoundResult } from "../../../app/models/round";
import {
  Modal,
  Header,
  Button,
  Icon,
  Confirm,
  Image,
  Popup,
} from "semantic-ui-react";
import { sortTiles } from "../../../app/common/util/util";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { TileStatus } from "../../../app/models/tileStatus";
import { ExtraPoint } from "../../../app/models/extraPointEnum";
import { PlayResult } from "../../../app/models/playResultEnum";
import btnClickSfx from "../../../app/common/sounds/btnClick.mp3";
import useSound from "use-sound";

const ResultModal: React.FC = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [playbtnClickSfx] = useSound(btnClickSfx);
  const handleCancel = () => setConfirmOpen(false);
  const rootStore = useContext(RootStoreContext);
  const { startRound, endGame, startRevenge } = rootStore.hubStore;
  const { getMainUser, gameIsOver, gameSound, game } = rootStore.gameStore;
  const { round, showResult, closeResultModal, boardActiveTile } =
    rootStore.roundStore;

  let winners: IRoundResult[] | null = null;
  let losers: IRoundResult[] | null = null;
  let tiePlayers: IRoundResult[] | null = null;
  let groupedLosers;

  const roundResults = round!.roundResults;
  const isHost = getMainUser?.isHost;
  const activeBoardTile = boardActiveTile;

  const doRevenge = () => {
    if (gameSound) {
      playbtnClickSfx();
    }
    startRevenge();
  };

  const doNextRound = () => {
    if (gameSound) {
      playbtnClickSfx();
    }
    startRound();
  };
  const groupLosersByName = (losers: IRoundResult[]) => {
    return Object.entries(
      losers.reduce((llist, l) => {
        llist[l.displayName] = llist[l.displayName]
          ? [...llist[l.displayName], l]
          : [l];
        return llist;
      }, {} as { [key: string]: IRoundResult[] })
    );
  };

  if (roundResults) {
    winners = roundResults?.filter((r) => r.playResult === PlayResult.Win)!;
    losers = roundResults?.filter(
      (r) =>
        r.playResult === PlayResult.Lost ||
        r.playResult === PlayResult.LostWithPenalty
    );
    tiePlayers = roundResults!.filter((r) => r.playResult === PlayResult.Tie);
    groupedLosers = groupLosersByName(losers);
  }

  return (
    <Fragment>
      <Modal
        className="resultModal"
        closeIcon
        open={showResult}
        onClose={closeResultModal}
        size="small"
      >
        <Header icon="bullhorn" content="Result" />

        <Modal.Content>
          {round?.isTied && <h3>booooo it's a tie. nobody win</h3>}
          {round?.isTied && game?.useJackpot && (
            <Fragment>
              <h3>
                <Image src="/assets/jackpot.png" avatar />
                Every player put 1 pt to jackpot
              </h3>
            </Fragment>
          )}

          {winners && (
            <Fragment>
              <h3>
                {winners.map((w) => (
                  <Fragment key={`winner-${w.userName}`}>
                    {`${w.displayName} ${PlayResult[
                      w.playResult
                    ].toLowerCase()} ${w.points} pts`}
                    <ul>
                      {w.roundResultHands.map((h, i) => (
                        <li key={i}>
                          {h.name} : {h.point}
                        </li>
                      ))}
                      {w.roundResultExtraPoints.map((e, i) => (
                        <li key={i}>
                          {e.name} : {e.point}
                        </li>
                      ))}
                    </ul>
                    <div className="flexTilesContainer">
                      {w.playerTiles
                        .slice()
                        .sort(sortTiles)
                        .map((rt) =>
                          rt.status === TileStatus.UserJustPicked ? (
                            <div
                              style={{ height: "55px", width: "40px" }}
                              key={rt.id}
                              className={`flexTilesSmall justPickedTile tile50px tile50px-${rt.tile.title}`}
                            />
                          ) : (
                            <div
                              key={rt.id}
                              className={`flexTilesSmall tile50px tile50px-${rt.tile.title}`}
                            />
                          )
                        )}
                      {!w.roundResultExtraPoints.some(
                        (ep) => ep.extraPoint === ExtraPoint.SelfPick
                      ) &&
                        activeBoardTile && (
                          <div
                            style={{ height: "55px", width: "40px" }}
                            className={`flexTilesSmall justPickedTile tile50px tile50px-${activeBoardTile.tile.title}`}
                          />
                        )}
                    </div>
                  </Fragment>
                ))}
              </h3>
            </Fragment>
          )}
          {groupedLosers && (
            <h3>
              {groupedLosers.map(([lname, lostList]) => (
                <Fragment key={lname}>
                  <ul>
                    {lostList.map((l, i) => (
                      <li key={`lost-${i}-${l.userName}`}>
                        {`${l.displayName} ${PlayResult[
                          l.playResult
                        ].toLowerCase()} ${l.points} pts`}
                      </li>
                    ))}
                  </ul>
                  <div className="flexTilesContainer">
                    {lostList[0]
                      .playerTiles!.slice()
                      .sort(sortTiles)
                      .map((rt) => (
                        <div
                          className={`flexTilesSmall tile50px tile50px-${rt.tile.title}`}
                          key={rt.id}
                        />
                      ))}
                  </div>
                </Fragment>
              ))}
            </h3>
          )}
          {tiePlayers && (
            <h3>
              {tiePlayers.map((p) => (
                <Fragment>
                  <ul>
                    <Fragment key={p.userName}>
                      <li>{p.displayName}</li>
                    </Fragment>
                  </ul>
                  <div className="flexTilesContainer">
                    {p.playerTiles
                      .slice()
                      .sort(sortTiles)
                      .map((rt) => (
                        <span
                          className={`flexTilesSmall tile50px tile50px-${rt.tile.title}`}
                          key={rt.id}
                        />
                      ))}
                  </div>
                </Fragment>
              ))}
            </h3>
          )}
        </Modal.Content>
        {!gameIsOver && isHost && (
          <Modal.Actions>
            <Button
              color="red"
              onClick={() => {
                if (gameSound) {
                  playbtnClickSfx();
                }
                setConfirmOpen(true);
              }}
            >
              <Icon name="handshake" /> End Game
            </Button>
            <Button color="blue" onClick={doNextRound}>
              <Icon name="play" /> Next Round
            </Button>

            <Popup
              content="Restart round to East + Shuffle seat + Keep player's points"
              trigger={
                <Button floated="left" color="violet" onClick={doRevenge}>
                  <Icon name="stopwatch" /> Revenge!{" "}
                </Button>
              }
              position="right center"
            />
          </Modal.Actions>
        )}
      </Modal>
      <Confirm
        open={confirmOpen}
        content="Are you sure you want to end the game?"
        onCancel={handleCancel}
        onConfirm={endGame}
      />
    </Fragment>
  );
};
export default observer(ResultModal);
