import { useField } from "formik";
import React, { Fragment } from "react";
import { Form, Label, Checkbox, Popup, Icon } from "semantic-ui-react";

interface Props {
  name: string;
  label: string;
  value: any;
  showPopup?: boolean;
  popupContent?: string;
}

export default function MyCheckbox(props: Props) {
  const [field, meta, helpers] = useField(props.name);
  return (
    <Form.Field error={meta.touched && !!meta.error}>
      <Checkbox
        {...field}
        value ={props.value}
        onChange={(e, d) => {
          helpers.setValue(d.checked);
        }}
        onBlur={() => helpers.setTouched(true)}
        label={props.label}
      />
      {props.showPopup && (
        <Fragment>
          {" "}
          <Popup content={props.popupContent} trigger={<Icon name="info circle" />} />
        </Fragment>
      )}
      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}
