import React from "react";
import { observer } from "mobx-react-lite";
import { Grid, Image } from "semantic-ui-react";
import PlayerStatus from "./PlayerStatus";
import { IRoundOtherPlayer } from "../../../app/models/player";
import { sortTiles } from "../../../app/common/util/util";
import ActionActivated from "./ActionActivated";
import PrevailingWindIndicator from "./PrevailingWindIndicator";

interface IProps {
  player: IRoundOtherPlayer;
  isReversed: boolean;
}

const TileListOtherPlayerVertical: React.FC<IProps> = ({
  player,
  isReversed,
}) => {
  const displayClosedTile = () => {
    let closedTiles = [];
    for (let i = 0; i < player.activeTilesCount; i++) {
      closedTiles.push(
        <div
          key={`${player.userName}${i}`}
          style={{ height: "34px" }}
          {...(isReversed && { className: "rotate180" })}
        >
          <img alt="face-down-tile" src={"/assets/tiles/v50px/face-down.png"} />
        </div>
      );
    }
    return closedTiles;
  };

  return (
    <Grid {...(isReversed && { reversed: "computer" })}>
      <Grid.Column width={4} className="flexTilesVerticalContainer">
        <Grid>
          <Grid.Row>
            {!isReversed && (
              <Image
                style={{ maxHeight: "50px" }}
                circular
                src={player.image || "/assets/user.png"}
              />
            )}
            {isReversed && player.isInitialDealer && (
              <PrevailingWindIndicator />
            )}
          </Grid.Row>
          <Grid.Row>
            <div
              style={{ marginLeft: isReversed ? "10px" : "0px" }}
              className="playerStatusContainerVertical"
              {...(player &&
                player.isMyTurn &&
                player.mustThrow && {
                  className: "mustThrow playerStatusHeaderVertical",
                })}
              {...(player &&
                player.isMyTurn &&
                !player.mustThrow && {
                  className: "playerTurn playerStatusHeaderVertical",
                })}
            >
              <div
                style={{ minHeight: "280px" }}
                className="playerStatusHeaderVertical"
              >
                <span
                  className="rotate90"
                  {...(isReversed && { className: "rotateMinus90" })}
                >
                  <PlayerStatus player={player} />
                </span>
              </div>
            </div>
          </Grid.Row>
          <Grid.Row>
            {isReversed && (
              <Image
                style={{ maxHeight: "50px" }}
                verticalAlign="top"
                circular
                src={player.image || "/assets/user.png"}
              />
            )}
            {!isReversed && player.isInitialDealer && (
              <PrevailingWindIndicator />
            )}
          </Grid.Row>
        </Grid>
      </Grid.Column>

      <Grid.Column width={5} className="flexTilesVerticalContainer">
        {player && displayClosedTile()}
      </Grid.Column>

      <Grid.Column width={7} className="flexTilesVerticalContainer">
        {player &&
          player.graveyardTiles
            .slice()
            .sort(sortTiles)
            .map((rt) => (
              <div
                className={`rotate90 tile50px tile50px-${rt.tile.title}`}
                key={rt.id}
                style={{ marginBottom: "-15px" }}
                {...(isReversed && {
                  className: `rotateMinus90 tile50px tile50px-${rt.tile.title}`,
                })}
              />
            ))}
        {player.activatedAction && (
          <ActionActivated activatedAction={player.activatedAction} />
        )}
      </Grid.Column>
    </Grid>
  );
};
export default observer(TileListOtherPlayerVertical);
